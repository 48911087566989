import { useEffect } from 'react';
import { useUnmount } from 'react-use';
import { getWindow } from '@surfline/web-common';
import { useRouter } from 'next/router';
import { spotReportPath } from 'utils/urls';
import { useUserPreferredForecastView } from 'selectors/user';
import usePreferredForecastView from 'hooks/usePreferredForecastView';
import { debounce } from 'lodash';

const DEBOUNCE_REPLACE = 0;
const replaceHistory = (data: any, unused: string, url?: string | URL | null) => {
  const win = getWindow();
  if (win) {
    win.history.replaceState(data, unused, url);
  }
};
export const debounceHistoryReplace = debounce(replaceHistory, DEBOUNCE_REPLACE);

// The purpose of this function is to mark any desired key value pairs of the router query as having a value
// of undefined. This is used in this file to do this to all query params if the spotId changes from a user
// navigating to a new spot.
export const clearedRouterQuery = (obj: any) => {
  const entries = Object.entries(obj);
  const undefinedEntries = entries.map(([key, value]) => {
    if (key === 'spotId' || key === 'spotSlug') {
      return [key, value];
    }
    return [key, undefined];
  });
  return Object.fromEntries(undefinedEntries);
};

export const getKbygQueryParams = (queryObject: Record<string, string | boolean> | {}) =>
  Object.fromEntries(Object.entries(queryObject).filter(([key]) => key.includes('kbyg_')));

export type Props = {
  cameras: Array<any>;
  isAnonymous: boolean | undefined;
  isMultiCam: boolean | undefined;
  isWavePool?: boolean;
  primaryCamId: string | undefined;
  shouldUpdateUrlCamId?: boolean;
  spotId: string;
  spotName: string;
};

const useConstructSpotPageURL = ({
  cameras,
  isAnonymous,
  isMultiCam,
  isWavePool,
  primaryCamId,
  shouldUpdateUrlCamId = true,
  spotId,
  spotName,
}: Props) => {
  const win = getWindow();
  const router = useRouter();
  const preferredForecastView = useUserPreferredForecastView();
  const { preferredForecastViewLocalStorage } = usePreferredForecastView(!!isAnonymous);

  const queryParams =
    spotId === router?.query?.spotId ? router?.query : clearedRouterQuery(router?.query);

  const setSpotUrlAndHistory = ({ camIdValue }: { camIdValue: string | undefined }) => {
    const { hash, search } = win.location;
    const { state } = win.history;
    const searchParams = new URLSearchParams(search);

    const spotUrl = spotReportPath(
      {
        ...queryParams,
        camId: camIdValue,
        view:
          searchParams.get('view')?.toLowerCase() === 'table' ||
          (isAnonymous && preferredForecastViewLocalStorage !== null
            ? preferredForecastViewLocalStorage === 'TABLE'
            : preferredForecastView === 'TABLE')
            ? 'table'
            : undefined,
      },
      {
        _id: spotId,
        name: spotName,
        isWavePool,
      },
    );
    const newUrl = `${spotUrl}${hash}`;
    debounceHistoryReplace({ ...state, as: newUrl, url: newUrl }, '', newUrl);
  };

  // This effect invokes setSpotUrlAndHistory and it is what takes care of almost all of our URL construction.
  useEffect(() => {
    if (!win) return () => {};

    // add camId to URL, we have multiple cams and one is selected
    if (!isMultiCam && cameras.length > 1 && shouldUpdateUrlCamId) {
      setSpotUrlAndHistory({ camIdValue: primaryCamId || cameras?.[0]?._id });
      return () => {
        debounceHistoryReplace.cancel();
      };
    }
    // drop camId from URL, we're on multicam view
    if (shouldUpdateUrlCamId) {
      setSpotUrlAndHistory({ camIdValue: undefined });
    }
    return () => {
      debounceHistoryReplace.cancel();
    };
    // We are not going to bypass this warning, however, we do not want to listen for every time the query object
    // updates so we leave router.query out of this equation.
  }, [
    isMultiCam,
    primaryCamId,
    preferredForecastView,
    preferredForecastViewLocalStorage,
    spotId,
    cameras,
    spotName,
    isAnonymous,
    win,
    shouldUpdateUrlCamId,
  ]);

  useUnmount(() => {
    debounceHistoryReplace.cancel();
  });

  return null;
};

export default useConstructSpotPageURL;
