import { parseISO, isAfter, isBefore } from 'date-fns';

type LimitedTimePremiumPlusConfig = {
  start: string;
  end: string;
};

const isLimitedTimePremiumPlusEligible = (
  limitedTimePremiumPlusConfig: LimitedTimePremiumPlusConfig | null | undefined,
): boolean => {
  if (
    !limitedTimePremiumPlusConfig ||
    !limitedTimePremiumPlusConfig.start ||
    !limitedTimePremiumPlusConfig.end
  ) {
    return false;
  }

  const { start, end } = limitedTimePremiumPlusConfig;
  const currentDate = new Date();
  const startDate = parseISO(start);
  const endDate = parseISO(end);

  return isAfter(currentDate, startDate) && isBefore(currentDate, endDate);
};

export default isLimitedTimePremiumPlusEligible;
