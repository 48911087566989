import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { Typography } from '@mui/material';
import { clearWavetrakIdentity, trackEvent, getWindow } from '@surfline/web-common';
import { CLICKED_SUBSCRIBE_CTA } from 'common/constants';
import { doUpdateUserSettings } from 'actions/user';
import { useAppDispatch } from 'stores/hooks';
import { useUserEntitlementStatus } from 'selectors/user';

import WavetrakLink from 'components/WavetrakLink';
import isPremiumEntitled from '../../../helpers/isPremiumEntitled';

import BaseMenu from '../BaseMenu';
import BaseMenuSection from '../BaseMenuSection';
import BaseMenuItem from '../BaseMenuItem';
import { accountLinks, homeLinks, promotionalLinks, productLinks } from '../../defaultLinks';

import styles from './UserMenu.module.scss';

interface UserMenuProps {
  user?: {
    email?: string;
    firstName?: string;
    lastName?: string;
    isEmailVerified?: boolean;
  } | null;
  entitlements?: Array<string>;
  showHome?: boolean;
  showDetails?: boolean;
  onClickLink?: (clickProperties?: any, closeMobileMenu?: boolean) => void;
  freeTrialEligible?: boolean;
  accountWarning?: boolean;
  featureRelease?: { title: string; date: string; url?: string };
  premiumPerks?: { date: string; url?: string };
  showFeatureReleaseNotification?: boolean;
  showPremiumPerksNotification?: boolean;
  isPremiumPlusSplitEnabled?: boolean;
}

const UserMenu = ({
  accountWarning = false,
  entitlements = [],
  freeTrialEligible = false,
  onClickLink,
  showDetails = false,
  showHome = false,
  user = null,
  featureRelease,
  premiumPerks,
  showFeatureReleaseNotification = false,
  showPremiumPerksNotification = false,
  isPremiumPlusSplitEnabled = false,
}: UserMenuProps) => {
  const router = useRouter();
  const dispatch = useAppDispatch();

  const handleOnClickCTA = useCallback(() => {
    trackEvent(CLICKED_SUBSCRIBE_CTA, {
      location: 'Account dropdown',
      path: router.asPath,
      url: `${window?.location.host}${router.asPath}`,
    });
  }, [router.asPath]);

  const clearNotificationAndRedirect = (
    hasNotification: boolean,
    clearNotification: Function,
    buttonText: string,
    redirectUrl: string,
  ) => {
    if (hasNotification) clearNotification();
    trackEvent('Button Clicked', {
      buttonText,
      pageName: window?.location.href,
      uiElement: 'User menu',
    });

    setTimeout(() => {
      getWindow()?.location.assign(redirectUrl);
    }, 300);
  };

  const handleClickedFeatureRelease = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      const redirectUrl = featureRelease?.url || 'https://go.surfline.com/whatsnew';
      clearNotificationAndRedirect(
        showFeatureReleaseNotification,
        () => dispatch(doUpdateUserSettings({ releaseAnnouncementDate: featureRelease?.date })),
        'New Release',
        redirectUrl,
      );
    },
    [dispatch, featureRelease, showFeatureReleaseNotification],
  );

  const handleClickedPremiumPerks = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      const redirectUrl = premiumPerks?.url || 'http://surfline.com/lp/perks';
      clearNotificationAndRedirect(
        showPremiumPerksNotification,
        () => dispatch(doUpdateUserSettings({ premiumPerkDate: premiumPerks?.date })),
        'Premium Perks',
        redirectUrl,
      );
    },
    [dispatch, premiumPerks, showPremiumPerksNotification],
  );

  const handleSignOut = () => {
    /**
     * resets Segment session
     * https://segment.com/docs/sources/website/analytics.js/#reset-logout
     */
    window?.analytics?.reset();
    /**
     * resets Braze session (v3 api)
     * https://js.appboycdn.com/web-sdk/latest/doc/modules/appboy.html#wipedata
     */
    window?.appboy?.wipeData();
    window?.Sprig?.('logoutUser');
    clearWavetrakIdentity();
    setTimeout(() => window.location.assign('/logout'), 300);
  };

  const isSubscribed = useUserEntitlementStatus();
  const isPremiumPlus = entitlements.includes('sl_premium_plus');
  const isForecastOnly = entitlements.includes('sl_forecast_only');
  const isPremiumUserWithSplitEnabled =
    (isPremiumEntitled(entitlements) || isForecastOnly) && isPremiumPlusSplitEnabled;
  let showCta =
    entitlements.includes('sl_forecast_only') || freeTrialEligible || isPremiumUserWithSplitEnabled;
  if (isPremiumPlus) showCta = false;
  let upgradeLink = isSubscribed ? '/account/subscription' : '/upgrade';
  if (isPremiumUserWithSplitEnabled) {
    upgradeLink = '/upgrade';
  }
  let upgradeText = 'Upgrade now';
  if (isPremiumEntitled(entitlements)) {
    upgradeText = 'Upgrade to Premium+ now';
  }

  const premiumStatusText = useMemo(() => {
    if (isPremiumPlus) return 'Premium+ member';
    if (isForecastOnly) return 'Forecast member';
    return 'Premium member';
  }, [isPremiumPlus, isForecastOnly]);

  const renderNotification = () => {
    if (accountWarning) {
      return (
        <WavetrakLink
          className={classNames(styles.notification, styles.notificationWarning)}
          href="/account/subscription"
          isExternal
        >
          <Typography component="span" variant="subtitle1">
            Update Payment Details
          </Typography>
        </WavetrakLink>
      );
    }
    if (isSubscribed) {
      return (
        <div
          className={classNames({
            [styles.premiumStatus]: true,
            [styles.premiumPlusStatus]: isPremiumPlus,
          })}
        >
          <Typography component="span" variant="subtitle1">
            {premiumStatusText}
          </Typography>
        </div>
      );
    }
    if (user && !user?.isEmailVerified) {
      return (
        <WavetrakLink
          className={classNames(styles.notification, 'quiver-user-menu__notification')}
          href="/account/edit-profile"
          isExternal
        >
          <Typography component="span" variant="subtitle1">
            Verify your email
          </Typography>
        </WavetrakLink>
      );
    }
    return null;
  };

  return (
    <BaseMenu position="right" testId="user-menu">
      {showHome ? <BaseMenuSection links={homeLinks} onClick={onClickLink} /> : null}
      {user && showDetails ? (
        <BaseMenuSection>
          <div
            className={classNames({
              [styles.userDetailsSectionPremiumPlus]: isPremiumPlus,
            })}
            data-testid="user-details-section"
          >
            <div className={classNames(styles.userName, 'quiver-user-menu__user-name')}>
              <Typography component="span" variant="body">
                {user.firstName}
              </Typography>{' '}
              <Typography component="span" variant="body">
                {user.lastName}
              </Typography>
            </div>
            <div className={classNames(styles.userEmail, 'quiver-user-menu__user-email')}>
              <Typography component="span" variant="caption1">
                {user.email}
              </Typography>
            </div>
            {renderNotification()}
          </div>
        </BaseMenuSection>
      ) : null}
      {showCta ? (
        <BaseMenuSection>
          <BaseMenuItem href={upgradeLink} isExternal onClick={handleOnClickCTA}>
            <div className={classNames(styles.cta)}>
              <Typography
                color="text.primary"
                component="span"
                data-testid={freeTrialEligible ? 'navbar-user-menu-trial' : undefined}
                variant="subtitle1"
              >
                {freeTrialEligible ? 'Try Premium free for 7 days' : upgradeText}
              </Typography>
            </div>
          </BaseMenuItem>
        </BaseMenuSection>
      ) : null}
      {user ? (
        <>
          <BaseMenuSection links={accountLinks} onClick={onClickLink} />
          {featureRelease ? (
            <BaseMenuSection>
              <BaseMenuItem
                href="https://go.surfline.com/whatsnew"
                onClick={handleClickedFeatureRelease}
                isExternal
              >
                <div className={styles.featureRelease}>
                  <div>{featureRelease?.title || "What's New"}</div>
                  {showFeatureReleaseNotification ? (
                    <div className={styles.featureReleaseNotification}>
                      <Typography component="div" variant="subtitle1" color="white">
                        NEW
                      </Typography>
                    </div>
                  ) : null}
                </div>
              </BaseMenuItem>
            </BaseMenuSection>
          ) : null}
          {premiumPerks ? (
            <BaseMenuSection>
              <BaseMenuItem
                href="http://surfline.com/lp/perks"
                onClick={handleClickedPremiumPerks}
                isExternal
              >
                <div className={styles.premiumPerks}>
                  <div>Perks</div>
                  {showPremiumPerksNotification ? (
                    <div className={styles.premiumPerksNotification}>
                      <Typography component="div" variant="subtitle1" color="white">
                        NEW
                      </Typography>
                    </div>
                  ) : null}
                </div>
              </BaseMenuItem>
            </BaseMenuSection>
          ) : null}
          <BaseMenuSection>
            <BaseMenuItem href="https://support.surfline.com/hc/en-us" isExternal>
              <div className={styles.premiumPerks}>
                <div>Help Center</div>
              </div>
            </BaseMenuItem>
          </BaseMenuSection>
          <BaseMenuSection links={promotionalLinks} onClick={onClickLink} />
          <BaseMenuSection links={productLinks} onClick={onClickLink} />
          <BaseMenuSection>
            <BaseMenuItem href="#logout" onClick={handleSignOut}>
              Sign Out
            </BaseMenuItem>
          </BaseMenuSection>
        </>
      ) : null}
    </BaseMenu>
  );
};

export default UserMenu;
